





























































































import { Component, Vue } from 'vue-property-decorator';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sorting';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FilterButtonBar from '@/components/partials/FilterButtonBar.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import SearchBar from '@/components/partials/SearchBar.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import { InnovamoUser, UserState } from '@/apis/userapi';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import UserTableCard from '@/components/table/UserTableCard.vue';
import Table from '@/components/table/Table.vue';
import ButtonType from '@/assets/buttonTypes';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    CustomSelect,
    DeleteDialog,
    RoundedButtonFilled,
    TextHeader,
    SearchBar,
    FilterButtonBar,
    ConfirmDialog,
    UserTableCard,
    Table,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class UserManagement extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private SortBy = Sort;
  private isLoading = true;
  private showUnexpectedErrorMessage = false;

  private role: Role = Role.DEVELOPER;
  private userId!: string;

  private showDeleteDialog = false;
  private deleteDialogMessage: string = '';
  private showConfirmDialog = false;
  private confirmDialogMessage: string = '';
  private userToDeleteUuid: string | undefined = undefined;

  private noItemsFoundMessage = 'Es konnten keine Benutzer, Entwickler oder Admins gefunden werden oder ihre Suche ergab keine Treffer.';

  private sortValue: Option = {
    name: 'Name (auf)',
    value: Sort.NAME_ASCENDING,
  };

  private sortOptions: Option[] = [
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
  ];

  private searchTerm: string = '';

  private selectedStateFilters: Array<'unlocked' | 'blocked' | 'examination'> = [];

  private currentPage: number = 0;
  private totalPages: number = 0;
  private itemsPerPage: number = 10;
  private totalNumberOfItems: number = 0;

  private userStates = {
    unlocked: {
      name: 'unlocked',
      text: 'Freigeschaltet',
      icon: 'icon-check-circle',
      cssClasses: 'w-7 h-7 text-green-500 rounded-full mr-2',
      apiRequestValue: UserState.Unlocked,
    },
    blocked: {
      name: 'blocked',
      text: 'Geblockt',
      icon: 'icon-alert-circle',
      cssClasses: 'w-6 h-6 p-1 bg-red-500 text-white rounded-full mr-2',
      apiRequestValue: UserState.Blocked,
    },
    examination: {
      name: 'examination',
      text: 'In Prüfung',
      icon: 'icon-question-circle',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full mr-2',
      apiRequestValue: UserState.Examination,
    },
  };

  private users: InnovamoUser[] = [];

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private get rows() {
    return this.users.map((user) => ({
      id: user.id,
      userId: user.userId,
      userState: this.mapResponsePublishStateEnumToPublishState(user.userState as UserState),
      name: `${user.firstname} ${user.lastname}`,
      role: this.mapRoleStringToViewString(user.role?.role as string),
      company: user.companyName ? user.companyName : '-',
      registerAt: user.registerDate ? `${this.dateTimeFormatter.format(new Date(Date.parse(user.registerDate as string)))} Uhr` : '-',
      lastLoginAt: user.lastLoginDate ? `${this.dateTimeFormatter.format(new Date(Date.parse(user.lastLoginDate as string)))} Uhr` : '-',
    }));
  }

  private get publishStateFiltersForRequest(): UserState[] {
    return this.selectedStateFilters.map((s) => this.userStates[s].apiRequestValue);
  }

  private mapResponsePublishStateEnumToPublishState(state: UserState) {
    switch (state) {
      case UserState.Unlocked:
        return this.userStates.unlocked;
      case UserState.Blocked:
        return this.userStates.blocked;
      default:
        return this.userStates.examination;
    }
  }

  private get currentItemStart(): number {
    return this.currentPage * this.itemsPerPage + 1;
  }

  private get currentItemEnd(): number {
    return this.currentItemStart + this.users.length - 1;
  }

  private async mounted() {
    try {
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    } catch (e) {
      this.users = [];
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.isLoading = false;
    }
  }

  private mapRoleStringToViewString(role: string): string {
    switch (role) {
      case 'User':
        return 'Bürger:in';
      case 'Developer':
        return 'Entwickler:in';
      case 'Admin':
        return 'Admin';
      default:
        return '-';
    }
  }
  private editUser(userId: string, role: string, name:string) {
    this.$router.push({
      name: 'UserEditing',
      params: {
        userId: userId,
        textHeaderProp: `${role} ${name} bearbeiten`,
      },
    });
  }

  private async deleteUser(userId: string, role: string, name:string) {
    let numberOfProjects;
    let numberOfSurveys;
    try {
      const response = await Services.projects.getProjectsByDeveloperUserId(userId);
      numberOfProjects = response.data?.length;
    } catch (error) {
      numberOfProjects = 0;
    }

    try {
      const response = await Services.surveys.getAllSurveys();
      numberOfSurveys = response.data.filter((s) => s.developer_id === userId).length;
    } catch (error) {
      numberOfSurveys = 0;
    }

    if (numberOfProjects === 0 && numberOfSurveys === 0) {
      if (role === '-') {
        this.deleteDialogMessage = `Soll "${name}" wirklich gelöscht werden?`;
      } else {
        this.deleteDialogMessage = `Soll der ${`${role} "${name}`}"  wirklich gelöscht werden?`;
      }

      this.showDeleteDialog = true;
      this.userToDeleteUuid = userId;
    } else {
      this.confirmDialogMessage = 'Dieser Entwickler kann nicht gelöscht werden, da dieser noch Projekte und/oder Umfragen besitzt. Diese Projekte oder Umfragen müssen vorher gelöscht werden.';
      this.showConfirmDialog = true;
    }
  }

  private async deleteUserConfirmed() {
    this.showDeleteDialog = false;

    if (this.userToDeleteUuid) {
      try {
        await Services.users.deleteUser(this.userToDeleteUuid);
        this.users = this.users.filter((user) => user.id !== this.userToDeleteUuid);
      } catch (error) {
        console.log(error);
      }

      this.userToDeleteUuid = undefined;
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    }
  }

  private deleteUserCanceled() {
    this.showDeleteDialog = false;
    this.userToDeleteUuid = undefined;
  }

  private async searchUsers(pageNumber: number = 0, searchTerm: string, itemsPerPage: number, sortBy: Option): Promise<InnovamoUser[]> {
    try {
      const response = await Services.users.getAllUsersPaginated(searchTerm, pageNumber, itemsPerPage, sortBy.value.order, this.publishStateFiltersForRequest);
      this.currentPage = response.data.currentPage as number;
      this.totalNumberOfItems = response.data.totalItemsFound as number;
      this.totalPages = response.data.totalPages as number;
      if (response.data.users) {
        return response.data.users;
      }
      return [];
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
      return [];
    }
  }

  private async userStateSelectionChanged(selectedStateFilters: ('unlocked'|'blocked'|'examination')[]) {
    this.selectedStateFilters = selectedStateFilters;
    this.currentPage = 0;
    this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
  }

  private async sortValueChanged() {
    this.currentPage = 0;
    this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
  }

  private async searchButtonClicked(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
  }

  private async itemsPerPageChanged(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.users = await this.searchUsers(this.currentPage, this.searchTerm, itemsPerPage, this.sortValue);
  }

  private async toFirstPage() {
    if (this.currentPage > 0) {
      this.currentPage = 0;
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    }
  }

  private async previousPage() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    }
  }

  private async nextPage() {
    if (this.currentItemEnd < this.totalNumberOfItems) {
      this.currentPage += 1;
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    }
  }

  private async toLastPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage = this.totalPages - 1;
      this.users = await this.searchUsers(this.currentPage, this.searchTerm, this.itemsPerPage, this.sortValue);
    }
  }

}
